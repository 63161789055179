<template>
  <div>
    <span v-if="erstattunggesamt == 0">
      <h3>
        Für {{ tarif.tier_title }} wurde noch keine Berechnung hinterlegt.
      </h3>
      Wir werden diese in den nächsten Tagen hinterlegen.
    </span>
    <span v-if="erstattunggesamt > 0">
      <h3>Benchmark {{ tarif.tier_title }} | so wurde der Wert ermittelt</h3>
      <v-row>
        <v-col cols="12" md="12"
          >Von 28 Behandlungen in 5 Versicherungsjahren, erstattet die
          {{ tarif.tier_title }} {{ erstattungen }}-mal. Insgesamt sind während
          den 5 Jahren {{ rechnungengesamt }} € an Tierarzt Rechnungen
          angefallen. Davon hat der
          {{ tarif.tier_title }}
          <b class="green--text">{{ erstattunggesamt }},00 € erstattet</b>. Dies
          enstpricht einer Erstattung von
          <b class="green--text">{{ tarif.tier_wert }} %</b></v-col
        >
      </v-row>
      <br />
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left" style="min-width:120px;">Behandlung</th>
            <th class="text-left">Versicherung-Jahr</th>
            <th class="text-left">Kosten</th>
            <th class="text-left">Erstattung</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in benchmark" :key="item.bench_id">
            <td>
              {{ mixinWertFilter(item.bench_field, "benchmark", "title") }}
            </td>
            <td>{{ item.bench_year }}</td>
            <td>
              {{ mixinWertFilter(item.bench_field, "benchmark", "kosten") }} €
            </td>

            <td>
              <span
                v-html="
                  mixinWertFilterCompare(item.bench_field, item.bench_value)
                "
              ></span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </span>
    <!-- <v-row class="my-4">
      <v-col cols="12" md="4" xs="4"><b>Behandlung</b></v-col>
      <v-col cols="12" md="2" xs="2"><b>Versicherung-Jahr</b></v-col>
      <v-col cols="12" md="2" xs="2"><b>Kosten</b></v-col>
      <v-col cols="12" md="2" xs="2"><b>Erstattung</b></v-col>
    </v-row>

    <v-row v-for="item in benchmark" :key="item.bench_id">
      <v-col cols="12" md="4">{{
        mixinWertFilter(item.bench_field, "benchmark", "title")
      }}</v-col>
      <v-col cols="12" md="2">{{ item.bench_year }}</v-col>
      <v-col cols="12" md="2">
        {{ mixinWertFilter(item.bench_field, "benchmark", "kosten") }} €
      </v-col>
      <v-col cols="12" md="2">
        <span
          v-html="mixinWertFilterCompare(item.bench_field, item.bench_value)"
        ></span
      ></v-col>
    </v-row> -->
  </div>
</template>

<script>
  import fieldMixin from "../mixins/fieldMixin";
  export default {
    name: "index-rechenweg",
    mixins: [fieldMixin],
    props: ["tarif"],
    components: {},
    data: function() {
      return {
        benchmark: false,
        erstattungen: 0,
        rechnungengesamt: 5260,
        erstattunggesamt: 0,
        select: {
          type: "einzel",
          id: this.tarif.tier_id,
          number: "one",
        },
      };
    },
    created() {
      this.getTarifBenchmark();
    },

    methods: {
      calcBenchmarkDetails() {
        this.$log.debug("benchstart");
        let anzahl = 0;
        this.erstattunggesamt = 0;
        this.benchmark.forEach((item) => {
          if (item.bench_value > 0) {
            this.erstattunggesamt =
              this.erstattunggesamt + parseInt(item.bench_value);
            anzahl = anzahl + 1;
          }
        });

        this.erstattungen = anzahl;
      },
      getTarifBenchmark() {
        this.$log.debug("bench");
        this.$store
          .dispatch("getTarifBenchmark", this.select)
          .then((resp) => {
            if (resp.data.benchmark) {
              this.benchmark = resp.data.benchmark;
              this.calcBenchmarkDetails();
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
    },
    computed: {},
  };
</script>
