<template>
  <div class="pl-4 mt-4">
    <v-row>
      <v-col cols="12" md="9" xs="12">
        <v-row>
          <v-col cols="12" md="9">
            <h2>
              {{ tarif.tier_title }}
            </h2>
            <v-text-field v-model="checktest" />
          </v-col>
          <v-col cols="12" md="3"> </v-col>
        </v-row>

        <span v-if="isAdmin && checktest == 'wurliwurli'">
          <br />
          <a @click="tarif.show_edit = !tarif.show_edit">Leistungen Admin</a>
          <br />
          <a :href="`/preise/${tarif.tier_id}`">Preise Admin</a>
          <br />
          <a :href="`/antragadmin/${tarif.tier_id}`">Antrag Admin</a>
          <br />
          <a :href="`/wertadmin/${tarif.tier_id}`">WaizmannWert Admin</a>
          <br />
          <a @click="addBeispiel = !addBeispiel"
            >Erstattungsbeispiel hinzufügen</a
          >
          <br />

          <a @click="showUpload = !showUpload">Dokument uploaden</a>
          <br />

          <div
            v-if="showUpload"
            class="pa-4 mb-6"
            style="background-color: #f2f2f2"
          >
            <v-row class="pa-4 mb-6">
              <v-col cols="12" md="6">
                <b>Art des Dokuments?</b><br />
                <v-radio-group v-model="newFile.doc_typ">
                  <v-radio label="AVB" value="3"></v-radio>
                  <v-radio label="Flyer" value="2"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6">
                <b>Für wen?</b><br />
                <v-radio-group v-model="newFile.doc_status">
                  <v-radio label="für Kunde" value="0"></v-radio>
                  <v-radio label="nur Intern" value="1"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Datei Name"
                  v-model="newFile.doc_text"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <input
                  type="file"
                  id="file"
                  ref="file"
                  @change="setNewFile()"
                />
                <br />{{ newFile.userfile }}
              </v-col>
            </v-row>
            <v-row v-if="newFile.doc_text">
              <v-col cols="12" md="12">
                <v-btn @click="saveNewFile()">Datei jetzt speichern</v-btn>
                <v-alert type="success" v-model="showInsertSuccess"
                  ><h2>Es hat geklappt</h2></v-alert
                >
              </v-col>
            </v-row>
          </div>

          <div v-if="addBeispiel">
            <v-row>
              <v-col cols="12" md="3">Datum</v-col>
              <v-col cols="12" md="9"
                ><v-text-field v-model="insertbeispiel.bei_created_date" />
                2020-04-30</v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" md="3">Rechnung</v-col>
              <v-col cols="12" md="9"
                ><v-text-field v-model="insertbeispiel.bei_rechnung"
              /></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">Erstattung</v-col>
              <v-col cols="12" md="9"
                ><v-text-field v-model="insertbeispiel.bei_erstattung"
              /></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">Title</v-col>
              <v-col cols="12" md="9"
                ><v-text-field v-model="insertbeispiel.bei_title"
              /></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">Details</v-col>
              <v-col cols="12" md="9"
                ><v-textarea v-model="insertbeispiel.bei_text"
              /></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">save</v-col>
              <v-col cols="12" md="9">
                <v-btn @click="startInsertBeispiel()"
                  >Beispiel speichern</v-btn
                ></v-col
              >
            </v-row>
          </div>
          <v-row>
            <v-col cols="12" md="3">Test-Note</v-col>
            <v-col cols="12" md="9"
              ><v-text-field v-model="inserttest.test_value"> </v-text-field>
              <v-btn @click="startInsertTest()">test speichern</v-btn>
              <br />
              {{ testberichte }}
            </v-col>
          </v-row>
          <v-dialog v-model="tarif.show_edit" width="1000">
            <v-card class="pa-4">
              <v-card-text>
                <v-row class="mb-4">
                  <v-col cols="12" md="12">
                    <h2 class="mb-2">Edit</h2>
                    <v-btn @click="updateTarif()" color="blue" outlined
                      >Update Tarif</v-btn
                    ></v-col
                  >
                </v-row>

                <div v-for="item in origin" :key="item.value">
                  <v-row
                    v-if="item.edit"
                    class="mb-4"
                    style="background-color: #f2f2f2"
                  >
                    <v-col cols="12" md="3" xs="12"
                      ><br />
                      <b>{{ item.title }}</b>
                      <br />{{ item.value }} | {{ item.type }}
                    </v-col>
                    <v-col cols="12" md="9" xs="12">
                      <div v-if="item.type == 'text' || item.type == 'int'">
                        <v-text-field v-model="tarif[item.value]" />
                      </div>
                      <div v-if="item.type.type == 'textarea'">
                        <v-textarea v-model="tarif[item.value]"></v-textarea>
                      </div>
                      <div v-if="item.type == 'radio'">
                        <v-radio-group v-model="tarif[item.value]">
                          <v-radio
                            v-for="(ivalue, ipropertyName) in item.options"
                            :key="ipropertyName"
                            :label="ivalue.label"
                            :value="ivalue.value"
                          >
                            {{ ipropertyName }}: {{ ivalue }}</v-radio
                          >
                        </v-radio-group>
                      </div>

                      <div v-if="item.beschreibung != ''" class="pa-4">
                        <b class="green--text">Info: </b>
                        {{ item.beschreibung }}
                      </div>
                    </v-col>
                  </v-row>
                </div>

                <v-btn @click="updateTarif()" color="blue" outlined
                  >Update Tarif</v-btn
                >
              </v-card-text>
            </v-card>
          </v-dialog>
        </span>
        <br />
        <v-tabs style="width: 888px">
          <v-tab class="nocaps">Leistungsbeschreibung</v-tab>
          <v-tab class="nocaps">Eisbaum Testverfahren</v-tab>
          <v-tab class="nocaps">Beitragstabelle</v-tab>
          <v-tab class="nocaps" v-if="beispiele.length > 0"
            >Erstattungsbeispiele</v-tab
          >
          <v-tab class="nocaps" v-if="avb.length > 0">Downloads</v-tab>

          <v-tab-item class="mt-4 pl-3">
            <v-row
              v-for="item in origin"
              :key="item.value"
              class="mb-2"
              style="width: 888px;"
            >
              <v-col
                cols="12"
                md="12"
                v-if="
                  !item.showintern &&
                    showFieldComputedFilled(item.value, 'hund')
                "
              >
                <v-row style="border-bottom:1px solid #f2f2f2">
                  <v-col cols="12" md="3" xs="12">
                    {{ item.title }}
                    <!-- <br />{{ item.value }} | {{ item.type }}--></v-col
                  >
                  <v-col cols="12" md="8" xs="12">
                    <b
                      ><span class="pb-0 mb-0">
                        {{ showFieldComputed(item.value, "hund") }}</span
                      ></b
                    >
                  </v-col>
                  <v-col>
                    <a
                      v-if="item.spezialpop"
                      @click="item.show_spezial_pop = !item.show_spezial_pop"
                      >mehr Infos...</a
                    >
                    <v-dialog
                      v-if="item.spezialpop"
                      v-model="item.show_spezial_pop"
                      width="888"
                    >
                      <spezial-pop
                        :item="tarif"
                        :type="item.spezialpop"
                      ></spezial-pop>
                    </v-dialog>
                    <ButtonTip
                      v-if="item.popup && !item.spezialpop"
                      cssstyle="
                 
                
                  cursor: pointer;
                "
                      text="Info"
                      icon="mdi-delete"
                      :tooltip="item.value"
                      eventname="deleterec"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="mt-4 pl-3"
            ><index-rechenweg :tarif="tarif" />
          </v-tab-item>
          <v-tab-item class="mt-4 pl-3"
            >Dies ist der Preis für die Krankenversicherung eines
            Cocker-Spaniels bei der {{ tarif.tier_title }}. Je jünger der Hund
            beim Versicherungsabschluss ist desto günstiger ist der monatliche
            Beitrag.
            <v-row v-for="item in preise" :key="item.preis_id">
              <v-col cols="12" md="2">{{ item.preis_alter }} Jahre</v-col>
              <v-col cols="12" md="2">{{ item.preis_value_1 }} €</v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item v-if="beispiele.length > 0" class="pt-4">
            <v-row v-for="item in beispiele" :key="item.bei_id">
              <v-col cols="12" md="8"
                ><b>{{ item.bei_title }}</b> <br />{{ item.bei_text }}
              </v-col>
              <v-col cols="12" md="2"
                ><b>{{ item.bei_rechnung }} €</b>
                <br />
                <span class="caption">Tierarzt-Rechnung</span>
              </v-col>
              <v-col cols="12" md="2"
                ><b class="green--text">{{ item.bei_erstattung }} €</b>

                <br />
                <span class="caption"
                  >Erstattung durch {{ tarif.tier_title }}</span
                ></v-col
              >
            </v-row>
          </v-tab-item>
          <v-tab-item v-if="avb.length > 0">
            <ul>
              <li v-for="item in avb" :key="item.doc_id">
                <a
                  :href="`/dist/tarif/${item.doc_dateiname}`"
                  target="_blank"
                  >{{ item.doc_text }}</a
                >
              </li>
            </ul>
          </v-tab-item>
        </v-tabs>
      </v-col>
      <v-col cols="12" md="3" xs="12"
        ><rechner-start-box />

        <v-card outlined class="mt-8" v-if="tarif.un_abschluss_url != ''">
          <v-card-text
            ><h2>Online-Abschluss</h2>
            <p>
              Hier können Sie die <b>{{ tarif.tier_title }}</b> sofort online
              beantragen.
            </p>

            <v-btn
              class="nocaps mb-4"
              style="background-color: #d84315; color: #fff"
            >
              <a
                v-if="tarif.un_abschluss_url != ''"
                @click="saveAntragClickend()"
                :href="tarif.un_abschluss_url"
                target="_blank"
                style="color: #fff"
                >jetzt online beantragen
              </a></v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import fieldMixin from "./../mixins/fieldMixin";
  import ButtonTip from "./../base/helper/ButtonTip";
  import RechnerStartBox from "./../base/helper/RechnerStartBox";
  import SpezialPop from "./../base/helper/SpezialPop";
  import IndexRechenweg from "./IndexRechenwegBak.vue";
  export default {
    name: "beschreibung-gross",
    components: {
      ButtonTip,
      SpezialPop,
      IndexRechenweg,
      RechnerStartBox,
    },
    mixins: [fieldMixin],
    data: function() {
      return {
        updateSeo: {
          seo_type: 1,
          seo_object_id: 0,
          seo_title: "",
        },
        checktest: "",
        addBeispiel: false,
        testberichte: false,
        showInsertSuccess: false,
        showUpload: false,
        isAdmin: true,
        tarif: false,
        selectpreise: {
          type: "alle",
          id: 0,
          number: "array",
        },
        avb: false,
        preise: false,
        origin: false,
        table: "hund",
        selectupdate: {
          type: "einzel",
          id: 0,
          number: "one",
        },
        newFile: {
          userfile: false,
          doc_tarif_id: 0,
          doc_text: "",
          doc_status: "0",
          doc_typ: "3",
        },
        beispiele: false,
        insertbeispiel: {
          bei_created_date: "",
          bei_title: "",
          bei_text: "",
          bei_hund_id: 1,
          bei_rechnung: 0,
          bei_erstattung: 0,
          bei_tarif_id: 0,
        },
        inserttest: {
          test_tarif_id: 0,
          test_value: "",
          test_type: 1,
          test_year: 2021,
        },
        selecturl: {
          type: "einzel",
          id: this.$route.params.id,
          number: "one",
        },
        select: {
          type: "einzel",
          id: 0,
          number: "one",
        },
        selectantrag: {
          action: "saveclickantrag",
          tier_id: 0,
        },
        showTbFields: {
          tier_title: {
            field: "tier_title",
            table: "hund",
          },
          tier_abrechnung_direkt: {
            field: "tier_abrechnung_direkt",
            table: "hund",
          },
          tier_intern_ersattvoraussetzung: {
            field: "tier_intern_ersattvoraussetzung",
            table: "hund",
          },
        },
      };
    },
    created() {
      this.getTarife();
      //this.checkAdmin();

      this.origin = this.hund;
    },

    methods: {
      saveAntragClickend() {
        this.$store
          .dispatch("apiTarifPost", this.selectantrag)
          .then(() => {})
          .catch((err) => {
            this.$log.error(err);
          });
      },
      saveReferrer() {
        var string = document.referrer,
          substring = ".google";
        var isGoogle = string.indexOf(substring) !== -1;
        if (isGoogle) {
          this.updateSeo.seo_type = 1;
          this.updateSeo.seo_object_id = this.tarif.tier_id;
          this.updateSeo.seo_title = document.title;
          this.$store.dispatch("saveReferrer", this.updateSeo);
        }
      },
      getAvb() {
        this.$log.debug("getAvB", this.tarif.tier_id);
        this.$store.dispatch("getAvb", this.tarif.tier_id).then((resp) => {
          if (resp.data.avb) {
            this.avb = resp.data.avb;
          }
        });
      },
      saveNewFile() {
        this.$log.debug("submit File");
        this.newFile.doc_tarif_id = this.tarif.tier_id;
        if (this.$refs.file.files[0]) {
          // this.$store.commit("start_loading_full");
          let formData = new FormData();
          formData.append("file", this.newFile.userfile);
          formData.set("doc_tarif_id", this.tarif.tier_id);
          formData.set("doc_text", this.newFile.doc_text);
          formData.set("doc_typ", this.newFile.doc_typ);
          formData.set("doc_status", this.newFile.doc_status);
          this.$log.debug("submit File drinnen", formData);
          this.$store
            .dispatch("submitFileAvb", {
              formData: formData,
            })
            .then((resp) => {
              if (resp.data.id > 0) {
                this.$log.debug("success upload", resp);
                this.$store.commit("stop_loading_full");
                this.showFileUpdload = false;
                this.showInsertSuccess = true;
                this.newFile.doc_typ = "3";
                this.newFile.doc_status = "0";
                this.newFile.vodc_text = "";
                this.getAvb(this.tarif.tarif_id);
              } else {
              }
            })
            .catch((err) => {
              this.$log.error(err);
            });
        }
      },
      setNewFile() {
        this.newFile.userfile = this.$refs.file.files[0];
        this.$log.debug("new File", this.newFile.userfile);
      },
      checkAdmin() {
        this.$store
          .dispatch("checkAdmin")
          .then((resp) => {
            if (resp.data.isadmin) {
              this.isAdmin = resp.data.isadmin;
            }
            //  document.dispatchEvent(new Event("x-app-rendered"));
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      setTitle() {
        if (this.tarif.tier_id > 0) {
          this.$log.debug("title");

          document.title =
            this.tarif.tier_title +
            " Krankenversicherung Hund " +
            this.tarif.tier_wert +
            " % von 100 % im Eisbaum-Test";
          let metadesc =
            this.tarif.tier_title +
            " Krankenversicherung Hund " +
            this.tarif.tier_wert +
            " % von 100 % im Eisbaum-Test (5-Jahres Realwert-Methode)";
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", metadesc);
        }
      },
      getPreise() {
        this.selectpreise.id = this.tarif.tier_id;
        this.$store
          .dispatch("getPreise", this.selectpreise)
          .then((resp) => {
            if (resp.data.preise) {
              this.preise = resp.data.preise;
            }
            //  document.dispatchEvent(new Event("x-app-rendered"));
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },

      getBeispiele() {
        this.$store
          .dispatch("getBeispiele", this.selectpreise)
          .then((resp) => {
            if (resp.data.beispiele) {
              this.beispiele = resp.data.beispiele;
            }
            //  document.dispatchEvent(new Event("x-app-rendered"));
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      getTestberichte() {
        this.$store
          .dispatch("getTestberichte", this.selectpreise)
          .then((resp) => {
            if (resp.data.testberichte) {
              this.testberichte = resp.data.testberichte;
            }
            // document.dispatchEvent(new Event("x-app-rendered"));
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      startInsertBeispiel() {
        this.$store.commit("start_loading_full");
        this.insertbeispiel.bei_tarif_id = this.tarif.tier_id;
        this.$store
          .dispatch("startInsertBeispiel", this.insertbeispiel)
          .then((resp) => {
            this.$store.commit("stop_loading_full");
            this.insertbeispiel.bei_title = "";
            this.insertbeispiel.bei_text = "";
            this.insertbeispiel.bei_created_date = "";
            this.insertbeispiel.bei_rechnung = 0;
            this.insertbeispiel.bei_erstattung = 0;
            this.getBeispiele();
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      startInsertTest() {
        this.$store.commit("start_loading_full");
        this.inserttest.test_tarif_id = this.tarif.tier_id;
        this.$store
          .dispatch("startInsertTest", this.inserttest)
          .then((resp) => {
            this.$store.commit("stop_loading_full");
            this.inserttest.test_value = "";
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      updateTarif() {
        let updateData = {};
        updateData["tier_id"] = this.tarif.tier_id;
        for (const [key, value] of Object.entries(this.origin)) {
          if (value.edit) {
            this.$log.debug("field", value.value);
            updateData[value.value] = this.tarif[value.value];
          }
          // this.$log.debug("field", key);
          // this.$log.debug("value", value.noedit);
        }
        this.$log.debug("updateData", updateData);
        this.$store.commit("start_loading_full");

        this.$store
          .dispatch("updateTarif", updateData)
          .then((resp) => {
            this.$store.commit("stop_loading_full");
            this.tarif.show_edit = false;
            updateData = false;
            setTimeout(this.getTarife(), 1000);
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      getTarife() {
        this.$store
          .dispatch("getTarifeUrl", this.selecturl)
          .then((resp) => {
            if (resp.data.tarife) {
              this.tarif = resp.data.tarife;
              this.select.id = this.tarif.tier_id;
              this.selectantrag.id = this.tarif.tier_id;
              this.selectantrag.name = this.tarif.tier_title;
              this.setTitle();
              this.saveReferrer();
              this.getTestberichte();
              this.getPreise();
              this.getBeispiele();
              this.getAvb();
              document.dispatchEvent(new Event("x-app-rendered"));
            }
          })
          .catch((err) => {
            this.$log.error(err);
            document.dispatchEvent(new Event("x-app-rendered"));
          });
      },
    },
  };
</script>
